<template>
  <v-dialog v-model="show" width="600px" content-class="admin-reject-prompt-modal" persistent>
    <v-card class="pa-5">
      <h1>Enter the reason for rejection</h1>
      <v-card-text class="mt-10">
        <v-textarea outlined color="secondary" v-model="rejectReason"></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" outlined :elevation="0" @click="confirm"> Confirm </v-btn>
        <v-btn color="warning" outlined :elevation="0" @click="show = false"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["promptShow"],
  data() {
    return {
      rejectReason: "",
    };
  },
  methods: {
    confirm() {
      this.$emit("reject", this.rejectReason);
      this.show = false;
    },
  },
  computed: {
    show: {
      get() {
        return this.promptShow;
      },
      set(value) {
        this.rejectReason = "";
        this.$emit("update:promptShow", value);
      },
    },
  },
};
</script>

<style></style>
