<template>
  <div class="admin">
    <div class="page-title mb-8">
      <v-icon color="secondary">mdi-shield-crown</v-icon>
      <p class="title-1">Admin Page</p>
      <v-spacer />
      <v-btn outlined color="secondary" v-if="$store.state.user.userRole < 0" @click="userLoginClicked"
        >User Login</v-btn
      >
    </div>

    <v-card outlined flat rounded="xl" class="pa-8">
      <v-tabs v-model="tabIndex">
        <v-tab>회사 생성 신청 목록</v-tab>
        <v-tab>회사 가입 신청 목록</v-tab>
      </v-tabs>
      <div class="contents">
        <v-tabs-items v-model="tabIndex">
          <v-tab-item>
            <company-manage class="company-manage" />
          </v-tab-item>
          <v-tab-item>
            <company-join-manage class="company-join-manage" />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>

    <confirm
      :show.sync="userLoginShow"
      :confirmButton="{ text: 'LOGIN', params: userLoginEmail }"
      :cancelButton="{ text: 'CANCEL' }"
      @confirm="userLogin">
      <span> 로그인 할 유저의 이메일을 입력해주세요. </span>
      <v-text-field outlined color="secondary" v-model="userLoginEmail" dense @keyup.enter="userLogin"></v-text-field>
    </confirm>
  </div>
</template>

<script>
import CompanyManage from "@/components/admin/companyCreate/CompanyManage.vue";
import CompanyJoinManage from "@/components/admin/companyJoin/CompanyJoinManage.vue";
import Confirm from "@/components/common/cards/Confirm.vue";
export default {
  components: {
    CompanyManage,
    CompanyJoinManage,
    Confirm,
  },
  data() {
    return {
      tabIndex: 0,
      userLoginShow: false,
      userLoginEmail: "",
    };
  },
  methods: {
    userLoginClicked() {
      this.userLoginShow = true;
    },
    userLogin() {
      this.$store.dispatch("user/superLogin", { email: this.userLoginEmail }).then(() => {
        this.$store.dispatch("user/getCompanyList").then(() => {
          this.$router.push("/");
        });
      });
    },
  },
};
</script>

<style></style>
