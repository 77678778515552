<template>
  <v-card class="pa-4">
    <div class="py-4" style="width: 500px">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="search"
        outlined
        dense
        hide-details></v-text-field>
    </div>

    <v-data-table
      hide-default-footer
      disable-sort
      :search="search"
      :headers="headers"
      :items="filteredItems"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      :no-data-text="$t('common.text.noData')"
      :no-results-text="$t('common.text.noMatchingResult')">
      <template v-slot:[`item.businessLicense`]="{ item }">
        <div v-if="item.businessLicense">
          <v-menu v-model="item.menu" :close-on-content-click="false" :nudge-width="200" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn elevation="0" v-bind="attrs" v-on="on" outlined color="secondary"> VIEW IMAGE </v-btn>
            </template>

            <v-card class="admin-popover-license" min-width="350px">
              <img :src="`${$s3}${item.businessLicense}`" alt="license" class="fw" />
            </v-card>
          </v-menu>
        </div>
        <span v-else> - </span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="success" v-bind="attrs" v-on="on" @click="companyApproval(item)">
                mdi-check-circle-outline
              </v-icon>
            </template>
            <span>승인</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="error"
                v-bind="attrs"
                v-on="on"
                @click="
                  () => {
                    tempItem = item;
                    promptShow = true;
                  }
                "
                class="ml-2">
                mdi-close-circle-outline
              </v-icon>
            </template>
            <span>거절</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>

    <div class="text-center pt-8" v-if="pageCount > 0">
      <v-pagination v-model="page" :length="pageCount" color="secondary" :total-visible="$pagePV"></v-pagination>
    </div>

    <reject-prompt :promptShow.sync="promptShow" @reject="companyReject" />
  </v-card>
</template>

<script>
import codeHandler from "@/utils/codeHandler";
import RejectPrompt from "@/components/admin/companyCreate/RejectPrompt.vue";
import { hasValue } from "@/utils/util";

export default {
  components: { RejectPrompt },
  data() {
    return {
      headers: [
        { text: "COMPANY NAME", value: "name" },
        { text: "TYPE", value: "typeName" },
        { text: "COUNTRY", value: "countryName" },
        { text: "ADDRESS", value: "address" },
        { text: "POSTAL", value: "postal" },
        { text: "CONTACT NAME", value: "contactName" },
        { text: "CONTACT MAIL", value: "contactMail" },
        { text: "CONTACT CALL", value: "contactCall" },
        { text: "BUSINESS NUM", value: "businessNumber" },
        { text: "BUSINESS LICENSE", value: "businessLicense" },
        { text: "DATE", value: "sysRegDt" },
        { text: "", value: "actions", width: 50 },
      ],
      items: [],
      page: 1,
      itemsPerPage: 10,
      pageCount: 0,
      promptShow: false,
      tempItem: null,
      search: "",
    };
  },
  computed: {
    filteredItems() {
      return this.items
        .filter(i => i.isUse === "P")
        .map(i => ({
          cmpyUUID: i.cmpyUUID,
          name: i.name,
          typeName: i.typeName,
          countryName: i.countryName,
          address: i.address,
          postal: i.postal,
          contactName: i.contactName,
          contactMail: i.contactMail,
          contactCall: hasValue(i.contactCallCountry) ? `${i.contactCallCountry} ${i.contactCall}` : i.contactCall,
          businessNumber: i.businessNumber || "-",
          businessLicense: i.filePath || "",
          sysRegDt: this.$m(i.sysRegDt).format("yyyy-MM-DD"),
        }));
    },
  },
  created() {
    this.$http.get("/admin/company").then(res => {
      if (res.data.status === 200) this.items = res.data.data;
      else codeHandler(res.data);
    });
  },
  methods: {
    companyApproval(item) {
      this.$http.put("/admin/company", { cmpyUUID: item.cmpyUUID, isUse: "Y" }).then(res => {
        if (res.data.status === 200) {
          this.$toast.success("Approve Success");
          this.items.splice(
            this.items.findIndex(i => i.cmpyUUID === item.cmpyUUID),
            1,
          );
        } else codeHandler(res.data);
      });
    },
    companyReject(reason) {
      if (!this.tempItem) return;
      const item = this.tempItem;

      this.$http.put("/admin/company", { cmpyUUID: item.cmpyUUID, isUse: "D", rejectReason: reason }).then(res => {
        if (res.data.status === 200) {
          this.$toast.success("Reject Success.");
          this.items.splice(
            this.items.findIndex(i => i.cmpyUUID === item.cmpyUUID),
            1,
          );
        } else codeHandler(res.data);
      });
    },
    hasValue,
  },
};
</script>

<style lang="scss">
.company-manage {
  th {
    color: black !important;
  }
}
</style>
