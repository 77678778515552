<template>
  <v-card class="pa-4">
    <div class="py-4" style="width: 500px">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="search"
        outlined
        dense
        hide-details></v-text-field>
    </div>

    <v-data-table
      hide-default-footer
      disable-sort
      :search="search"
      :headers="headers"
      :items="filteredItems"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      :no-data-text="$t('common.text.noData')"
      :no-results-text="$t('common.text.noMatchingResult')">
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex">
          <v-btn text color="secondary" @click="clickedApproval(item)">승인</v-btn>
        </div>
      </template>
    </v-data-table>

    <div class="text-center pt-8" v-if="pageCount > 0">
      <v-pagination v-model="page" :length="pageCount" color="secondary" :total-visible="$pagePV"></v-pagination>
    </div>
  </v-card>
</template>

<script>
import codeHandler from "@/utils/codeHandler";

export default {
  data() {
    return {
      headers: [
        { text: "USER NAME", value: "userName" },
        { text: "EMAIL", value: "email" },
        { text: "COMPANY NAME", value: "companyName" },
        { text: "DATE", value: "sysRegDt" },
        { text: "", value: "actions", width: 50 },
      ],
      items: [],
      page: 1,
      itemsPerPage: 10,
      pageCount: 0,
      search: "",
    };
  },
  computed: {
    filteredItems() {
      return this.items.map(i => ({
        ...i,
        sysRegDt: this.$m(i.sysRegDt).format("yyyy-MM-DD"),
      }));
    },
  },
  created() {
    this.$http.get("/admin/user/company").then(res => {
      if (res.data.status === 200) this.items = res.data.data;
      else codeHandler(res.data);
    });
  },
  methods: {
    clickedApproval(item) {
      this.$http
        .put("/admin/user/company", { id: item.id, cmpyUUID: item.cmpyUUID, userUUID: item.userUUID })
        .then(res => {
          if (res.data.status === 200) {
            this.$toast.success("Approve Success");
            this.items.splice(
              this.items.findIndex(i => i.id === item.id),
              1,
            );
          } else codeHandler(res.data);
        });
    },
  },
};
</script>

<style lang="scss">
.company-join-manage {
  th {
    color: black !important;
  }
}
</style>
